import BaseController from 'app/backbone/helpers/BaseController';
import BookingDetailView from 'app/backbone/views/account/events/artists/bookings/Detail.view';
import * as BookingStatus from 'app/react/entities/accounts/booking_statuses/index';
import * as BookingType from 'app/react/entities/booking_types/index';
import * as ArtistType from 'app/react/entities/events/artist_types/index';
import * as Booking from 'app/react/entities/events/bookings/index';
import * as Day from 'app/react/entities/events/days/index';
import * as Event from 'app/react/entities/events/index';
import * as Performance from 'app/react/entities/events/performances/index';
import * as Stage from 'app/react/entities/events/stages/index';
import { parseQueryString } from 'app/react/helpers/_index';
import * as actions from 'app/react/state/actions/_index';
import CreateView from 'app/react/views/event/artists/bookings/views/create/CreateViewContainer';
import Overview from 'app/react/views/event/artists/bookings/views/overview/OverviewContainer';
import UpdateView, { BOOKING_CARD_ID, PERFORMANCE_CARD_ID } from 'app/react/views/event/artists/bookings/views/update/UpdateViewContainer';
import React from 'react';
import ContactsController from './ContactsController';
import CrewController from './CrewController';
import FilesController from './FilesController';
import GuestListController from './GuestListController';
import ItemsController from './ItemsController';
import ItineraryContactsController from './ItineraryContactsController';
import PerformersController from './PerformersController';
import PickupsController from './PickupsController';
import ReservationsController from './ReservationsController';
import RidersController from './RidersController';
import TasksController from './TasksController';
import TimeSlotsController from './TimeSlotsController';
import TransportsController from './TransportsController';

export const overviewRoute = (
    eventId = ':event'
) => `events/${eventId}/artists/bookings(?*query)`;

export const detailRoute = (
    eventId = ':event',
    bookingId = ':booking'
) => `events/${eventId}/artists/bookings/${bookingId}`;

export const createRoute = (
    eventId = ':event'
) => `events/${eventId}/artists/bookings/create`;

export const updateRoute = (
    eventId = ':event',
    bookingId = ':booking'
) => `events/${eventId}/artists/bookings/${bookingId}/edit`;

export const detailsRoute = (
    eventId = ':event',
    bookingId = ':booking'
) => `events/${eventId}/artists/bookings/${bookingId}`;

export const detailsParkingRoute = (
    eventId,
    bookingId
) => `${detailsRoute(eventId, bookingId)}#parking`;

export const detailsReservationsRoute = (
    eventId,
    bookingId
) => `${detailsRoute(eventId, bookingId)}#accommodation`;

export const detailsSendItineraryRoute = (
    eventId,
    bookingId
) => `events/${eventId}/artists/bookings/${bookingId}/send`;

export const detailsSendLoginRoute = (
    eventId,
    bookingId
) => `events/${eventId}/artists/bookings/${bookingId}/send-production-website-login`;

export const createTransportRoute = (
    bookingId
) => `booking/add-transport/${bookingId}`;

export const editTransportRoute = (
    eventId,
    bookingId,
    transportId
) => `events/${eventId}/artists/bookings/${bookingId}/transports/${transportId}`;

export const editPickupRoute = (
    eventId,
    bookingId,
    pickupId
) => `events/${eventId}/artists/bookings/${bookingId}/pickups/${pickupId}/edit`;

export default class BookingsController extends BaseController {

    constructor(options = {}) {
        super(options);
        this.router.route(overviewRoute(), null, this.overview.bind(this));
        this.router.route(detailRoute(), null, this.detail.bind(this));
        this.router.route(createRoute(), null, this.create.bind(this));
        this.router.route(updateRoute(), null, this.update.bind(this));
        this.subControllers = [
            new ContactsController({ parent: this }),
            new PerformersController({ parent: this }),
            new CrewController({ parent: this }),
            new FilesController({ parent: this }),
            new RidersController({ parent: this }),
            new TransportsController({ parent: this }),
            new PickupsController({ parent: this }),
            new ItemsController({ parent: this }),
            new TimeSlotsController({ parent: this }),
            new ReservationsController({ parent: this }),
            new GuestListController({ parent: this }),
            new ItineraryContactsController({ parent: this }),
            new TasksController({ parent: this }),
        ];
    }

    overview(eventId, queryString) {
        const { app, store, dispatch } = this;
        const filters = queryString !== null ? parseQueryString(queryString) : null;
        app.renderContentComponent(React.createElement(Overview, { store }));
        // Account ID is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        return Promise.all([
            dispatch(Booking.thunks.fetchAllByPaginationForCurrentEvent(null, null, filters)),
            dispatch(Day.thunks.fetchAllForCurrentContext()),
            dispatch(Stage.thunks.fetchAllForCurrentContext()),
            dispatch(ArtistType.thunks.fetchAllForCurrentContext()),
            dispatch(BookingType.actions.fetchAll()),
            dispatch(BookingStatus.thunks.fetchAllForCurrentContext()),
        ])
    }

    detail(eventId, bookingId) {
        this.app.renderContentView(new BookingDetailView());
    }

    create(eventId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(CreateView, { store }));
        // Account ID is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));

        return Promise.all([
            dispatch(Day.thunks.fetchAllForCurrentContext()),
            dispatch(Stage.thunks.fetchAllForCurrentContext()),
            dispatch(ArtistType.thunks.fetchAllForCurrentContext()),
            dispatch(BookingType.actions.fetchAll()),
            dispatch(BookingStatus.thunks.fetchAllForCurrentContext()),
        ]);
    }

    update(eventId, bookingId) {
        const { app, store, dispatch } = this;
        app.renderContentComponent(React.createElement(UpdateView, { store }));
        // Account ID is set from DOM in the initial state
        dispatch(Event.actions.setCurrentId(parseInt(eventId, 10)));
        dispatch(Booking.actions.setCurrentId(parseInt(bookingId, 10)));

        return Promise.all([
            dispatch(Booking.thunks.fetchCurrent()).then(() => {
                const state = store.getState();
                const booking = Booking.stateHelpers.getCurrent(state) || {};
                const performanceId = booking.performance;
                const performance = Performance.stateHelpers.getOne(state, performanceId) || {};
                dispatch(actions.setComponentState(BOOKING_CARD_ID, { values: booking }));
                dispatch(actions.setComponentState(PERFORMANCE_CARD_ID, { values: performance }));
            }),
            dispatch(Day.thunks.fetchAllForCurrentContext()),
            dispatch(Stage.thunks.fetchAllForCurrentContext()),
            dispatch(ArtistType.thunks.fetchAllForCurrentContext()),
            dispatch(BookingType.actions.fetchAll()),
            dispatch(BookingStatus.thunks.fetchAllForCurrentContext()),
        ]);
    }

    detail(eventId, bookingId) {
        this.app.renderContentView(new BookingDetailView());
    }

}
