import React from 'react'
import {
    Avatar,
    Badge,
    Grid,
    AddUserIcon,
} from 'ui-kit'
import { countries } from 'country-data'
import Header from 'app/react/components/layout/content/header/Header'
import Tooltip from 'app/react/components/util/Tooltip'
import BetaBadge from 'app/react/components/badges/BetaBadge'

const { Cell } = Grid;
const { Tabs, TitleMenu } = Header;
const { Title, Actions } = TitleMenu;
const { Tab } = Tabs;

const Action = ({
    url,
    iconClass,
    children,
}) => (
    <a href={url} className="button secondary padding-tiny-rl margin-tiny-left">
        {children
            ? children
            : <i className={iconClass} />
        }
    </a>
);

const BookingDetailsContentHeader = ({
    activeTab,
    eventId,
    bookingId,
    artistId,
    artistName,
    artistImageSize80,
    artistCountryCode,
    bookingStatusTitle,
    bookingStatusColorType,
    permissions = {
        manageArtistItems: true,
        manageArtistWebsiteSettings: true,
        manageOffers: true,
    },
    accountPermissions,
    isFetching,
    children,
}) => {
    const baseUrl = `/events/${eventId}/artists/bookings/${bookingId}`;
    const currentUrl = window.location.pathname;
    return (
        <Header>
            <TitleMenu>
                <div className="content--header_title">
                    {!isFetching && (
                        <Grid hasGutters={false} className="grid--center">
                            <Cell isAutoSized>
                                <Avatar
                                    size={5.2}
                                    name={artistName || ''}
                                    imageUrl={artistImageSize80}
                                />
                            </Cell>
                            <Cell isAutoSized className="margin-tiny-left">
                                <h2 className="no-margin-bottom">{artistName}</h2>
                                {artistCountryCode && (
                                    <p>{countries[artistCountryCode].name}</p>
                                )}
                            </Cell>
                            <Cell isAutoSized className="margin-tiny-left">
                                <Badge type={bookingStatusColorType}>{bookingStatusTitle}</Badge>
                            </Cell>
                        </Grid>
                    )}
                </div>
                <Actions>
                    {permissions.sendEmails && (
                        <Tooltip content="Send email">
                            <Action
                                url={`${baseUrl}/send`}
                                iconClass="bs-icon-send"
                            />
                        </Tooltip>
                    )}
                    {permissions.sendProductionWebsiteLogins && (
                        <Tooltip content="Send Production Website login">
                            <Action url={`${baseUrl}/send-production-website-login`}>
                                <AddUserIcon />
                            </Action>
                        </Tooltip>
                    )}
                    {permissions.downloadItinerary && (
                        <Tooltip content="Download itinerary">
                            <Action
                                url={`${baseUrl}/itinerary`}
                                iconClass="bs-icon-download"
                            />
                        </Tooltip>
                    )}
                    {accountPermissions.manageCrm && permissions.manageArtists && (
                        <Tooltip content="Edit artist">
                            <Action
                                url={`/crm/artists/${artistId}/edit?redirect=${currentUrl}`}
                                iconClass="bs-icon-artist"
                            />
                        </Tooltip>
                    )}
                </Actions>
            </TitleMenu>
            <Tabs>
                <Tab tabId="details" activeTab={activeTab} url={baseUrl} pageReload>
                    Details
                </Tab>
                {permissions.manageArtistItems && (
                    <Tab tabId="items" activeTab={activeTab} url={`${baseUrl}/items`} pageReload>
                        Items
                    </Tab>
                )}
                {permissions.manageArtistWebsiteSettings && (
                    <Tab tabId="artistsWebsite" activeTab={activeTab} url={`${baseUrl}/artist-website`} pageReload>
                        Production website
                    </Tab>
                )}
                {permissions.manageOffers && (
                    <Tab tabId="offers" activeTab={activeTab} url={`${baseUrl}/offers`} pageReload>
                        Offers
                    </Tab>
                )}
                {permissions.viewActivity && (
                    <Tab tabId="activity" activeTab={activeTab} url={`${baseUrl}/activity`} pageReload>
                        Activity
                    </Tab>
                )}
                <Tab tabId="comments" activeTab={activeTab} url={`${baseUrl}/comments`} pageReload>
                    Comments
                </Tab>
            </Tabs>
        </Header>
    )
};

export default BookingDetailsContentHeader
